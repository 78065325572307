<template>
    <v-container fluid :class="$attrs.class" style="height: 80%;">
        <v-data-table :items="resumo" :headers="headers" cols="12" :sort-by="sortBy" :sort-desc="sortDesc" class="fixed-header-table" fixed-header  height="500px" :items-per-page="-1">
            <template v-slot:top>
                <v-row class="borda ma-2" style="font-weight: bold; color: green;">
                    <v-col :cols="8"> TOTAL DA PRODUÇÃO </v-col>
                    <v-col :cols="4" class="text-right"> {{ somatorio }} </v-col>
                </v-row>
                <v-row class="borda ma-2">
                    <v-col :cols="4" style="color: blue;"> Total de Evoluções Lançadas: {{ totalEvolucoes }}  </v-col>
                    <v-col :cols="4" style="color: green;">  Total de Evoluções Validadas: {{ totalEvolucoesValidadas }}  </v-col>
                    <v-col :cols="4" style="color: red;">  Total de Evoluções "SEM COLETA DE ASSINATURA": {{ totalEvolucoesPerdidas }} </v-col>
                </v-row>
            </template>
             <template v-slot:[`item.qtd_ate`]="{ item }">
                <span style="color: blue;">{{ item.qtd_ate }}</span>
            </template>
            <template v-slot:[`item.qtd_assinadas`]="{ item }">
                <span style="color: green;">{{ item.qtd_assinadas }}</span>
            </template>
            <template v-slot:[`item.diferenca`]="{ item }">
                <span style="color: red;">{{ item.diferenca }}</span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>

import api from '../../http'

export default {
    name: 'Produtividade',
    props: {
        tableData: { type: Array },
    },
    data: () => ({
        prestador: { apelido: null },
        sortBy: ['colaborador'],
        sortDesc: [false],
        headers:[
            {text:'ID do prestador', value:'colaborador_id'},
            {text:'Prestador', value:'colaborador'},
            {text:'Especialidade', value:'especialidade_nome'},
            {text:'Qtd. evoluções', value:'qtd_ate'},
            {text:'Qtd. validada', value:'qtd_assinadas'},
            {text:'Evoluções perdidas', value:'diferenca'},
            {text:'Total da produção', value:'total_valor_a_pagar'},
        ]
    }),
    methods: {
        async get_prestador(id) {
            try {
                const res = await api.get(`contas/app-usuarios-id-nome/?prestador=${id}`);
                if (res.status === 200) {
                    console.log(res.data[0].apelido)
                return res.data[0];
                }
                return id;
            } catch (error) {
                console.error(error);
                return id;
            }
        },
        formattedTotal(val) {
            return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        aggregateData(data) {
            const aggregated = {};
            data.forEach(item => {
                if(aggregated[item.colaborador_id]) {
                    aggregated[item.colaborador_id].qtd_ate += item.qtd_ate;
                    aggregated[item.colaborador_id].qtd_assinadas += item.qtd_assinadas;
                    aggregated[item.colaborador_id].valor_a_pagar += item.valor_a_pagar;
                }
                else {
                    aggregated[item.colaborador_id] = {
                        colaborador_id: item.colaborador_id,
                        especialidade_nome: item.especialidade_nome,
                        colaborador: item.colaborador,
                        qtd_ate: item.qtd_ate,
                        qtd_assinadas: item.qtd_assinadas,
                        valor_a_pagar: item.valor_a_pagar
                    }
                }
            });
            return Object.values(aggregated);
        },
    },
    computed: {
        somatorio(){
            if (this.resumo.length === 0) return null
            let soma = 0
            this.resumo.forEach(e => {
                soma += e.total_valor_a_pagar_real
            })
            return this.formattedTotal(soma)
        },
        resumo() {
            if (this.tableData.length === 0) return []
            const resumoData = this.tableData[this.tableData.length - 1].resumo;
            const aggregatedData = this.aggregateData(resumoData);
            return aggregatedData.map((item) => ({
                colaborador_id: item.colaborador_id,
                colaborador: item.colaborador,
                especialidade_nome: item.especialidade_nome,
                qtd_ate: item.qtd_ate,
                qtd_assinadas: item.qtd_assinadas,
                diferenca: item.qtd_ate - item.qtd_assinadas,
                total_valor_a_pagar_real: item.valor_a_pagar,
                total_valor_a_pagar: this.formattedTotal(item.valor_a_pagar)
            }));
        },
        totalEvolucoes() {
            return this.resumo.reduce((total, item) => total + item.qtd_ate, 0);
        },
        totalEvolucoesValidadas() {
            return this.resumo.reduce((total, item) => total + item.qtd_assinadas, 0);
        },
        totalEvolucoesPerdidas() {
            return this.totalEvolucoes - this.totalEvolucoesValidadas;
        },
    }
}
</script>
<style>
.borda{
    border: 1px solid rgb(184, 184, 184);
    border-radius: 4px;
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
